import { loginError, loginSuccess, logoutSuccess } from './authSlice';
import logger from '../../logger';
import { resetApp } from '../app/actions';

export const logout =
    (data = null) =>
    (dispatch, getState, api) =>
        api.logout(data).then(() => {
            dispatch(resetApp());
            dispatch(logoutSuccess());
        });


export const requestOTP = (data) => (dispatch, getState, api) =>
    api.requestOTP(data)
        .then(({ success, requiresOtp }) => {
            console.log("actions::requestOTP=>", success, requiresOtp);
            // if (success) {
            //     if (requiresOtp) {
            //         return { success: true, requiresOtp: true };
            //     } else {
            //         dispatch(loginSuccess());
            //         return { success: true, requiresOtp: false };
            //     }
            // } else {
            //     console.log("Authentication failed");
            //     throw new Error('Authentication failed');
            // }
        })
        .catch((error) => {
            logger.error("requestOTP error =>",error);
            dispatch(loginError(error));
            return { success: false };
        });


export const login = (data) => (dispatch, getState, api) =>
    api.authenticate(data)
        .then(({ success, requiresOtp }) => {
            if (success) {
                console.log("actions::login=>", success, requiresOtp);
                if (requiresOtp) {
                    return { success: true, requiresOtp: true };
                } else {
                    dispatch(loginSuccess());
                    return { success: true, requiresOtp: false };
                }
            } else {
                console.log("Authentication failed");
                throw new Error('Authentication failed');
            }
        })
        .catch((error) => {
            logger.error(error);
            dispatch(loginError(error));
            return { success: false };
        });

export const verifyOtp = (data) => (dispatch, getState, api) =>
    api.verifyOtp(data)
        .then(({ success }) => {
            if (success) {
                dispatch(loginSuccess());
                return { success: true };
            } else {
                throw new Error('OTP verification failed');
            }
        })
        .catch((error) => {
            logger.error(error);
            dispatch(loginError(error));
            return { success: false };
        });