import axios from 'axios';

// Sanctum sagt, wir brauchen das?
axios.defaults.withCredentials = true;

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/v3/sanctum/csrf-cookie',
        baseURL: process.env.REACT_APP_API_ROOT, // FIXME: should be REACT_APP_API_AUTH
    });

const getAuthenticated = (data) =>
    axios({
        method: 'get',
        url: '/api/v3/users/me',
        baseURL: process.env.REACT_APP_API_ROOT,
    }).then((response) => ({ ...data, userV3: response.data.data }));

const authenticate = (data) =>
    data.credentials
        ? getCSRFCookie().then(() =>
              axios({
                  method: 'post',
                  url: '/v3/auth/login',
                  baseURL: process.env.REACT_APP_API_ROOT,
                  data: data.credentials,
              })
                  .then(() => getAuthenticated(data))
                  .catch((error) => {
                      if (error.response && error.response.data) {
                          throw error.response.data;
                      }
                      throw error;
                  })
          )
        : getAuthenticated(data);

const verifyCredentials = (data) => 
{
    console.log('verifyCredentials=>', data, process.env.REACT_APP_API_ROOT);
    axios({
        method: 'post',
        url: '/v3/auth/verifyCredentials',
        baseURL: process.env.REACT_APP_API_ROOT,
        data: data.credentials,
    }).then((response) => ({ ...data, userV3: response.data.data }));
}
 
const logout = () =>
    getCSRFCookie().then(() =>
        axios({
            method: 'post',
            url: '/v3/auth/logout',
            baseURL: process.env.REACT_APP_API_ROOT,
        })
    );

export const attachAuthentication = (api) => {
    api.onVerifyCredentials(verifyCredentials);
    api.onAuthenticate(authenticate);
    api.onLogout(logout);
};
